import "./App.css";
import React, { useState, useEffect } from "react";
import MenuBar from "./components/MenuBar";
import "../src/styles/homepage.css";
import AboutUs from "./pages/AboutUs";
import Homepage from "./pages/Homepage";
import FAQ from "./pages/FAQ";
import LoginLUA from "./pages/LoginLUA";
import Uploadphoto from "./pages/Uploadphoto";
import Verification from "./pages/Verification";
import LoginDC from "./pages/LoginDC";
import JoinDirector from "./pages/JoinDirector";
import Footer from "./components/Footer";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Config } from "./envConfig";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import DirectorsClubMain from "./pages/DirectorsClubMain";
import { memberInfo } from "./helper/memberInfo";
import DCMemberInfo from "./pages/DCMemberInfo";

import "bootstrap/dist/css/bootstrap.min.css";
import Cmslogin from "./pages/Cmslogin";

function App() {
  const [CMSMode, setCMSMode] = useState(false);
  const [memberNum, setMemberNum] = useState(
    sessionStorage.getItem("memberNum")
  );

  useEffect(() => {
    console.log(sessionStorage.getItem("CMSMode"));
    if (sessionStorage.getItem("CMSMode")) {
      setCMSMode(true);
    }
    // console.log(CMSMode);
    // console.log("asdasd", memberNum);
  });
  return (
    <>
      <div>
        <div className="homepage" />
        <MenuBar />
      </div>
      <div className="page-margin">
        <BrowserRouter>
          <Routes>
            <Route exact path={Config.path + "/"} element={<Homepage />} />
            <Route path={`${Config.path}/about`} element={<AboutUs />} />
            <Route path={`${Config.path}/joinDC`} element={<JoinDirector />} />
            <Route path={`${Config.path}/FAQ`} element={<FAQ />} />
            <Route path={`${Config.path}/loginDC`} element={<LoginDC />} />
            <Route path={`${Config.path}/loginLUA`} element={<LoginLUA />} />
            <Route
              path={`${Config.path}/Uploadphoto`}
              element={<Uploadphoto />}
            />
            <Route
              path={`${Config.path}/Verification`}
              element={<Verification />}
            />
            <Route
              path={`${Config.path}/dcpage/:${memberNum}`}
              element={<DirectorsClubMain />}
            />
            <Route
              path={`${Config.path}/memberinfo/:${memberNum}`}
              element={<DCMemberInfo />}
            />
            {/* <Route path={`${Config.path}/dcpage`}>
              <Route path={"/:id"} element={<DirectorsClubMain />} />
            </Route> */}
            <Route path={`${Config.path}/cmslogin`} element={<Cmslogin />} />
          </Routes>
        </BrowserRouter>
      </div>
      {CMSMode ? (
        <div className="CMS-btn">
          <IconButton
            onClick={() => {
              console.log("sdasd");
              sessionStorage.removeItem("CMSMode");
              window.location.reload(false);
            }}
          >
            <SettingsIcon sx={{ fontSize: 50 }} />
          </IconButton>
        </div>
      ) : null}

      <Footer />
    </>
  );
}

export default App;
