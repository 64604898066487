import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import SortableComp from "./SortableComp";

export default function OrderingModal(props) {
  const timerRef = useRef(null);
  const [newData, setNewData] = useState(props.data);

  const saveData = () => {
    props.postData(newData);
    timerRef.current = setInterval(() => {
      props.handleClose();
      return;
    }, 2000);
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <Modal
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 999999 }}
      show={props.show}
    >
      <Modal.Body>
        <SortableComp data={newData} setNewData={setNewData} />
        <Button variant="primary" onClick={saveData}>
          儲存
        </Button>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          返回
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
