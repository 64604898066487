import React, { useState } from "react";
import { Card, Button, Col } from "react-bootstrap";
import Switch from "react-ios-switch";
import "../styles/CardInfo.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";

export default function CardInfo(props) {
  const [showData, setShowData] = useState(props.data.active);
  return (
    <Card
      style={{
        width: "18rem",
        height: props.img ? "17rem" : "12rem",
        borderWidth: 1,
        boxShadow: "0px 2px 0px #9E9E9E",
        marginTop: "2vh",
      }}
    >
      <Card.Body>
        <Card.Title className="card-title">
          <div dangerouslySetInnerHTML={{ __html: props.title }} />
        </Card.Title>
        {props.img ? (
          <img
            src={props.img}
            style={{ marginTop: "4vh", width: "40%", height: "50%" }}
          />
        ) : null}

        <div
          className="btn-area"
          style={{ marginTop: props.img ? "1vh" : "10vh" }}
        >
          <Switch
            checked={showData}
            className="switch"
            onChange={() => {
              const temp = showData;
              setShowData(!showData);
              props.showData(!temp);
            }}
          />
          <div className="btn-edit">
            <IconButton
              onClick={() => {
                props.ckedit();
              }}
            >
              <EditIcon sx={{ fontSize: 25 }} />
            </IconButton>
          </div>

          <div className="btn-del">
            <IconButton
              onClick={() => {
                props.delete();
              }}
            >
              <DeleteForeverIcon sx={{ fontSize: 25 }} />
            </IconButton>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
