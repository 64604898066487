import React from "react";
import "../styles/FAQ.css";
import { Accordion } from "react-bootstrap";

export default function FAQComp(props) {
  return (
    <Accordion.Item eventKey={props.data.id} className="accordionitem">
      <Accordion.Header className="accordionheader">
        <div dangerouslySetInnerHTML={{ __html: props.data.title }} />
      </Accordion.Header>
      <Accordion.Body className="accordionbody">
        <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
      </Accordion.Body>
    </Accordion.Item>
  );
}
