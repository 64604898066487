import React, { useRef, useState, useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "../styles/menu.css";
import { ReactComponent as NavIcon } from "../img/svg/menu_icon.svg";
import { ReactComponent as LoginIcon } from "../img/svg/login_icon.svg";
import { ReactComponent as MemberIcon } from "../img/svg/memberIcon.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { Config } from "../envConfig";
import { memberInfo } from "../helper/memberInfo";
import { Button } from "react-bootstrap";

export default function MenuBar() {
  const navRef = useRef();

  const info = JSON.parse(sessionStorage.getItem("memberInfo"));

  const [memberNum, setMemberNum] = useState(
    sessionStorage.getItem("memberNum")
  );

  const [cmsMode, setCmsMode] = useState(
    sessionStorage.getItem("CMSMode") ? true : false
  );

  const showNavBar = () => {
    navRef.current.classList.toggle("close-nav");
    console.log("pressed");
  };

  useEffect(() => {
    console.log("check login", memberInfo.checkLogedin);
  });

  return (
    <>
      <BrowserRouter>
        <header className="navbar-fixed flex" style={{ zIndex: 999 }}>
          <NavIcon className="nav-icon" />

          <IconButton className="nav-toggle btn-open" onClick={showNavBar}>
            <MenuIcon />
          </IconButton>

          <nav ref={navRef} className=" ul-flex">
            {/* <ul className=" ul-flex" > */}
            <IconButton
              className="nav-toggle nav-close-toggle"
              onClick={showNavBar}
            >
              <CloseIcon />
            </IconButton>
            <li>
              <a className="nav-font-not-clicked" href={`${Config.path}/`}>
                關於Directors’ Club
              </a>
            </li>
            <li>
              <a
                className="nav-font-not-clicked"
                href={
                  memberNum != undefined
                    ? `${Config.path}/dcpage/${memberNum}`
                    : cmsMode == true
                    ? `${Config.path}/dcpage/cms`
                    : `${Config.path}/loginDC`
                }
              >
                尊享服務
              </a>
            </li>
            <li>
              <a
                className="nav-font-not-clicked"
                href={`${Config.path}/joinDC`}
              >
                加入總監會
              </a>
            </li>
            <li>
              <a className="nav-font-not-clicked" href={`${Config.path}/FAQ`}>
                常見問題
              </a>
            </li>
            <li>
              <a className="nav-font-not-clicked" href={`${Config.path}/about`}>
                聯絡我們
              </a>
            </li>
            <li>
              {memberNum === null ? (
                <a
                  className="nav-font-not-clicked"
                  href={`${Config.path}/loginDC`}
                >
                  <LoginIcon />
                </a>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                    width: "15vw",
                  }}
                >
                  <a
                    className="nav-font-not-clicked"
                    href={`${Config.path}/memberinfo/${memberNum}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                      }}
                    >
                      <MemberIcon
                        style={{
                          marginRight: "1vw",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "0.5vh",
                        }}
                      >
                        {info.last_name} {info.first_name}
                      </div>
                    </div>
                  </a>
                  <a
                    style={{
                      width: "30%",
                      height: 50,
                      backgroundColor: "#B1A390",
                      border: 0,
                      borderRadius: 8,
                      marginTop: "1vh",
                    }}
                    href={`${Config.path}`}
                  >
                    <Button
                      style={{
                        width: "100%",
                        height: 50,
                        backgroundColor: "#B1A390",
                        border: 0,
                        borderRadius: 8,
                      }}
                      onClick={() => {
                        sessionStorage.removeItem("memberInfo");
                        sessionStorage.removeItem("memberNum");
                        window.location.reload(false);
                      }}
                    >
                      登出
                    </Button>
                  </a>
                </div>
              )}
              {/* <a
                className="nav-font-not-clicked"
                href={
                  memberNum != undefined
                    ? `${Config.path}/dcpage/${memberNum}`
                    : `${Config.path}/loginDC`
                }
              >
                <LoginIcon />
              </a> */}
            </li>
            {/* </ul> */}
          </nav>
        </header>
      </BrowserRouter>
    </>
  );
}
