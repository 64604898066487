import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../styles/modal.css";

export default function AlertModal(props) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
      style={{ zIndex: 999999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>溫馨提示</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          加入成為保協總監會的會員 (請先加入保協成為會員)
          <br />
          <a href="https://dc.luahk.org/loginLUA" target="_blank">
            http://dc.luahk.org/loginLUA
          </a>
        </div>
        <div>
          - 溫馨提示: 如果您想成為保協總監會的會員，請先加入保協成為會員。
          <br />
          <br /> 入會:
          <a href="https://store.luahk.org/membership" target="_blank">
            https://store.luahk.org/membership
          </a>
        </div>
        <div>
          續會:
          <a href="https://store.luahk.org/renew_membership" target="_blank">
            https://store.luahk.org/renew_membership
          </a>
        </div>

        <br />
        <div>
          Becoming a member of the Directors' Club of LUAHK (Join LUAHK as a
          member first)
          <br />
          <a href="https://dc.luahk.org/loginLUA" target="_blank">
            http://dc.luahk.org/loginLUA
          </a>
        </div>
        <div>
          - Warm reminder: if you want to be a member of the Directors' Club of
          LUAHK,
          <br />
          <p style={{ marginLeft: 12 }}>please join LUAHK as a member first.</p>
          <br /> Join LUAHK as a member:
          <a href="https://store.luahk.org/membership" target="_blank">
            https://store.luahk.org/membership
          </a>
        </div>
        <div>
          Resume Membership of LUAHK:
          <a href="https://store.luahk.org/renew_membership" target="_blank">
            https://store.luahk.org/renew_membership
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
