export const ENV_DEV = "DEV";
export const ENV_UAT = "UAT";
export const ENV_PROD = "PROD";
export const ENV = ENV_PROD;
export const envSetting = [ENV_DEV, ENV_UAT, ENV_PROD];
const ConfigMap = {};

ConfigMap[ENV_DEV] = {
  path: "/DEV/build",
};
ConfigMap[ENV_UAT] = {
  path: "/UAT/build",
};
ConfigMap[ENV_PROD] = {
  path: "",
};

export const Config = {
  ...ConfigMap[ENV],
};
