import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

export default function PDFEditorModal(props) {
  const timerRef = useRef(null);
  const [newTitle, setNewTitle] = useState(
    props.type === "edit" ? props.data.title : ""
  );

  const [type, setType] = useState(
    props.type === "edit" ? props.data.content_type : ""
  );

  const [disabled, setDisabled] = useState(false);

  const [YTName, setYTName] = useState(
    props.type === "edit" ? props.data.content_name : ""
  );
  const [YTLink, setYTLink] = useState(
    props.type === "edit" ? props.data.content : ""
  );

  const [PDFFile, setPDFFile] = useState(
    props.type === "edit" ? props.data.content : ""
  );
  const [PDFFileName, setPDFFileName] = useState(
    props.type === "edit" ? props.data.content_name : ""
  );
  const [JPGFile, setJPGFile] = useState(
    props.type === "edit" ? props.data.image : ""
  );

  const [CMSData, setCMSData] = useState(props.data);

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const postData = () => {
    setDisabled(true);
    let data = {};
    if (type === "pdf") {
      data = {
        title: newTitle,
        content_type: type,
        content_name: PDFFileName,
        image: JPGFile,
        content: PDFFile,
      };
    }

    if (type === "youtube") {
      data = {
        title: newTitle,
        content_type: type,
        content_name: YTName,
        image: JPGFile,
        content: YTLink,
      };
    }

    if (props.type == "edit") {
      console.log("edit");
      const temp = { ...props.data, ...data };
      props.postData(temp);
      timerRef.current = setInterval(() => {
        props.handleClose();
        return;
      }, 2000);
    }
    if (props.type == "add") {
      console.log("add");
      const temp = { ...data, ordinal: 1, active: true };
      props.postData(temp);
      timerRef.current = setInterval(() => {
        props.handleClose();
        return;
      }, 2000);
    }
    setDisabled(false);
  };

  const PDFUpload = ({ target }) => {
    console.log(target.files[0]);
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setPDFFileName(target.files[0].name);
        setPDFFile(result);
      }
    });
  };

  const JPGUpload = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    console.log(JPGFile);
    if (target.files[0].size < 1000000) {
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          setJPGFile(result);
        }
      });
    } else {
      alert("文件不能大於1MB");
      setJPGFile("");
    }
  };

  const handleChangeText = (e) => {
    setNewTitle(e.target.value);
  };

  const handleYTLink = (e) => {
    setYTLink(e.target.value);
  };

  const handleYTName = (e) => {
    setYTName(e.target.value);
  };

  useEffect(() => {
    console.log("props.data", props.data);
  }, []);
  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);
  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 99999 }}
      show={props.show}
    >
      <Modal.Header>
        <h2>修改 文字設定</h2>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            <Row style={{ alignItems: "center" }}>
              <Col style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: "2vw", marginTop: "2.5vh" }}>
                  標題:
                </div>
                <Form.Control
                  value={newTitle}
                  onChange={handleChangeText}
                  type="text"
                  name="title"
                  style={{ width: "30vw " }}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2.5vh",
                }}
              >
                <div style={{ marginRight: "2vw" }}>Type: </div>
                <Form.Check
                  inline
                  defaultChecked={type === " youtube" || type === null}
                  onChange={() => {
                    setType("youtube");
                  }}
                  label="Youtube"
                  value="youtube"
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-1`}
                />
                <Form.Check
                  inline
                  defaultChecked={type === "pdf"}
                  onChange={() => {
                    setType("pdf");
                  }}
                  label="PDF"
                  value="PDF"
                  name="group1"
                  type={"radio"}
                  id={`inline-radio-2`}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ display: "flex", flexDirection: "row" }}>
                {type == "pdf" ? (
                  <>
                    <div style={{ marginRight: "2vw", marginTop: "2.5vh" }}>
                      PDF:
                    </div>
                    <Form.Control
                      type="file"
                      onChange={PDFUpload}
                      accept="application/pdf"
                      // value={CMSData.content_name}
                    />
                  </>
                ) : null}
                {type == "youtube" ? (
                  <>
                    <div style={{ marginRight: "2vw", marginTop: "2.5vh" }}>
                      YT:
                    </div>
                    <Form.Control
                      value={YTLink}
                      onChange={handleYTLink}
                      type="text"
                      name="title"
                      style={{ width: "20vw " }}
                    />
                    <div style={{ marginRight: "2vw", marginTop: "2.5vh" }}>
                      影片標題:
                    </div>
                    <Form.Control
                      value={YTName}
                      onChange={handleYTName}
                      type="text"
                      name="title"
                      style={{ width: "10vw " }}
                    />
                  </>
                ) : null}
              </Col>
              <Col style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    marginRight: "2vw",
                    marginTop: "2.5vh",
                    width: "4vw",
                  }}
                >
                  圖片:
                </div>
                <Form.Control
                  onChange={JPGUpload}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                />
              </Col>
            </Row>
          </Container>
        </Form>

        <Button
          variant="primary"
          onClick={postData}
          disabled={
            (PDFFile == "" && YTLink == "") || JPGFile == "" || disabled
              ? true
              : false
          }
        >
          儲存
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          返回
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
