import React, { useState, useEffect } from "react";
import "../styles/DCmain.css";
import PDFComp from "../CMSComponent/PDFComp";
import { PDFData } from "../helper/tempData";
import { titleData } from "../helper/tempData";
import CKEditorModal from "../CMSComponent/CKEditorModal";
import CardViewModal from "../CMSComponent/CardViewModal";
import axios from "../helper/axios";
import { LUA } from "../config";

export default function DirectorsClubMain() {
  const [CMSHover, setCMSHover] = useState(false);
  const [CMSMode, setCMSMode] = useState(false);
  const [CMSHoverSce, setCMSHoverSce] = useState(false);
  const [showCardViewModal, setShowCardViewModal] = useState(false);

  const [showCKEditorModal, setShowCKEditorModal] = useState(false);

  const [pdfData, setPdfData] = useState([]);
  useEffect(() => {
    getData();
    if (sessionStorage.getItem("CMSMode")) {
      setCMSMode(true);
    }
  }, []);
  const getData = async () => {
    // if (PDFData.success) {
    //   setPdfData(PDFData.result);
    // }
    const luaToken = LUA.luaToken;
    let apiResponse;

    const post = {
      token: luaToken,
    };

    axios.post("/get_dc_service.php", post).then((response) => {
      apiResponse = response.data;
      console.log(apiResponse);

      if (response.data.success) {
        setPdfData(response.data.result);
      }
    });
    return;
  };
  const DataMap = pdfData.map((data, index) => {
    if (pdfData.length > 0) {
      if (data.active) return <PDFComp key={index} data={data} />;
    }
  });

  return (
    <>
      <div
        className={
          CMSMode ? "DCmain-container content-border" : "DCmain-container"
        }
        onMouseOver={() => {
          if (CMSMode) {
            setCMSHoverSce(true);
          }
        }}
        onMouseOut={() => {
          if (CMSMode) {
            setCMSHoverSce(false);
          }
        }}
      >
        {CMSHoverSce ? (
          <button
            onClick={() => {
              setShowCKEditorModal(true);
              getData();
            }}
            className="cms-edit-btn"
          >
            文字編輯
          </button>
        ) : null}
        <h2 className="header-title">{titleData[0].data.content}</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          alignContent: "center",
          paddingLeft: "10vw",
          paddingRight: "10vw",
          marginBottom: "10vh",
          flexWrap: "wrap",
          width: "100vw",
          height: pdfData.length > 0 ? "auto" : "10vh",
        }}
        className={CMSMode ? "content-border" : null}
        onMouseOver={() => {
          if (CMSMode) {
            setCMSHover(true);
          }
        }}
        onMouseOut={() => {
          if (CMSMode) {
            setCMSHover(false);
          }
        }}
      >
        {showCKEditorModal ? (
          <CKEditorModal
            postion={"dcmain"}
            type={"edit"}
            show={showCKEditorModal}
            handleClose={() => {
              setShowCKEditorModal(false);
              window.location.reload(false);
            }}
            data={pdfData}
          />
        ) : null}
        {CMSHover ? (
          <button
            onClick={() => {
              setShowCardViewModal(true);
            }}
            className="cms-edit-btn"
          >
            文字編輯
          </button>
        ) : null}
        {DataMap}
      </div>
      {showCardViewModal ? (
        <CardViewModal
          apiData={pdfData}
          location={"dcmain"}
          show={showCardViewModal}
          handleClose={() => {
            setShowCardViewModal(false);
            getData();
          }}
        />
      ) : null}
    </>
  );
}
