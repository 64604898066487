import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import background from "../img/png/become_mem_background.png";
import "../styles/LoginLUA.css";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { ReactComponent as LUALoginIcon } from "../img/svg/LUALoginIcon.svg";
import { Config } from "../envConfig";

export default function LoginLUA() {
  let navigate = useNavigate();
  const routeNavigate = () => {
    let path = Config.path + "/Uploadphoto";
    navigate(path);
  };

  const postData = () => {};

  return (
    <div className="loginLUA">
      <div
        className="bg"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Container className="Container">
          <Row>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S1brown.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S2white.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S3white.png")} width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="div-container font">
        <Container style={{ maxWidth: "660px" }} className="loginLUA-container">
          <div className="loginLUA-title-bg">
            <LUALoginIcon className="icon" />
            <div className="lua-font">LUA會員登入</div>
          </div>
          <LoginForm disable={true} color={"#8F312D"} />
          {/* <div className="div-btn">
            <Button
              style={{
                width: 452,
                height: 48,
                backgroundColor: "#8F312D",
                border: 0,
                borderRadius: 24,
              }}
              className="btn"
              onClick={routeNavigate}
            >
              <div>登入</div>
            </Button>
          </div> */}
        </Container>
      </div>
    </div>
  );
}
