import React from "react";
import { Container, Button } from "react-bootstrap";
import LoginForm from "../components/LoginForm";
import background from "../img/png/loginDC_bg.png";
import "../styles/loginDC.css";

export default function LoginDC() {
  return (
    <div className="loginDC">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div className="div-container font">
          <Container
            style={{ maxWidth: "660px" }}
            className="loginDC-container"
          >
            <div className="login-title">Directors’ Club 會員登入</div>
            <LoginForm disable={false} color={"#7D6746"} />
            {/* <div className="div-btn">
              <Button
                style={{
                  width: "70%",
                  height: 50,
                  backgroundColor: "#7D6746",
                  border: 0,
                  borderRadius: 8,
                }}
              >
                登入
              </Button>
            </div> */}
          </Container>
        </div>
      </div>
    </div>
  );
}
