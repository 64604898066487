import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../styles/PDFComp.css";
import { ReactComponent as YouTube } from "../img/svg/Youtube.svg";
import { ReactComponent as PDFIcon } from "../img/svg/pdf.svg";

export default function PDFComp(props) {
  return (
    <Container className="card-container">
      <Row>
        <Col>
          <div className="pdf-title">
            <div className="font">{props.data.title}</div>
          </div>

          <img src={props.data.image} style={{ width: 256, height: 360 }} />
          <div>
            <a
              href={props.data.content}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button className="type-btn">
                <div className="btn-container">
                  {props.data.content_type === "pdf" ? (
                    <PDFIcon />
                  ) : (
                    <YouTube style={{ marginTop: "0.75vh" }} />
                  )}
                  <div className="font">{props.data.content_name}</div>
                </div>
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
