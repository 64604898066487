import React from "react";
import "../styles/dcmemberinfo.css";
import background from "../img/png/dcmemberbg.png";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DCMemberLogo } from "../img/svg/DCMemberLogo.svg";
import { ReactComponent as DCMemberInfos } from "../img/svg/DCMemberinfo.svg";
import { ReactComponent as DCMemberDetails } from "../img/svg/DCMemberDetails.svg";
import { ReactComponent as DCCompanyInfo } from "../img/svg/DCCompanyInfo.svg";
import { useNavigate } from "react-router-dom";
import { Config } from "../envConfig";
// import { DCmember } from "../helper/tempData";
import { DCmember } from "../helper/memberInfo";

export default function DCMemberInfo() {
  let navigate = useNavigate();
  const routeNavigate = () => {
    let path = Config.path + "/";
    navigate(path);
  };

  const info = JSON.parse(sessionStorage.getItem("memberInfo"));
  return (
    <div className="dcmemberinfo-container">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Container>
            <Row>
              <Col
                lg={"4"}
                style={{
                  textAlign: "center",
                }}
              >
                <div className="dcmember-logo">
                  <div className="dcmember-name-memberNum">
                    <DCMemberLogo />
                    <div className="dcmemberinfo-font-title font-margin">
                      {info.last_name} {info.first_name}
                    </div>
                    <div className="dcmemberinfo-font-memNum font-margin">
                      {info.member_num}
                    </div>
                  </div>
                </div>
                <div className="dcmember-memberinfo">
                  <div className="container">
                    <div className="title">
                      <DCMemberInfos />
                      <div className="dcmemberinfo-font-title font-padding-left">
                        會員資料
                      </div>
                    </div>
                    <hr className="hr" />
                    <div className="dcmemberinfo-font-content">
                      <div className="content">
                        <div>密碼：</div>
                        <div>{info.password}</div>
                      </div>
                      <div className="content">
                        <div>會員編號：</div>
                        <div>{info.member_num}</div>
                      </div>
                      <div className="content">
                        <div>網址：</div>
                      </div>
                      <div className="content">
                        <div>建議人會員編號：</div>
                      </div>
                      <div className="content">
                        <div>附議人會員編號：</div>
                      </div>
                      <div className="content">
                        <div>
                          <div>在會員搜尋中</div>
                          <div>顯示會員編號：</div>
                        </div>
                        <div>{info.show_option ? "是" : "否"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={"8"} style={{ height: "620px" }}>
                <div className="dcmember-memberDetails">
                  <div className="first-container">
                    <div className="title">
                      <DCMemberDetails />
                      <div className="dcmemberinfo-font-title font-padding-left">
                        個人資料
                      </div>
                    </div>
                    <hr className="hr" />
                    <div className="dcmemberinfo-font-content">
                      <div className="content">
                        <div>稱號：</div>
                        <div>{info.title}</div>
                      </div>
                      <div className="content">
                        <div>性別：</div>
                        <div>{info.gender}</div>
                      </div>
                      <div className="content">
                        <div>國藉：</div>
                        <div>{info.nationality}</div>
                      </div>
                      <div className="content">
                        <div>出生日期：</div>
                        <div>{info.birthdate}</div>
                      </div>
                      <div className="content">
                        <div>身份證號碼：</div>
                        <div>{info.hkid}</div>
                      </div>
                      <div className="content">
                        <div>教育程度：</div>
                        <div>{info.education}</div>
                      </div>
                      <div className="content">
                        <div>專業教育：</div>
                        <div>{info.prof_education}</div>
                      </div>
                      <div className="content">
                        <div>手機號碼：</div>
                        <div>{info.mobile_phone}</div>
                      </div>
                    </div>
                  </div>
                  <div className="sceond-container">
                    <div className="title">
                      <DCCompanyInfo />
                      <div className="dcmemberinfo-font-title font-padding-left">
                        公司資料
                      </div>
                    </div>
                    <hr className="hr" />
                    <div className="dcmemberinfo-font-content">
                      <div className="content">
                        <div>職位：</div>
                        <div>{info.position}</div>
                      </div>
                      <div className="content">
                        <div>加入保險行業日期：</div>
                        <div>{info.entry_ins}</div>
                      </div>
                      <div className="content">
                        <div>公司名稱：</div>
                        <div>{info.org_name}</div>
                      </div>
                      <div className="content">
                        <div>公司編號：</div>
                        <div>{info.unit_code}</div>
                      </div>
                      <div className="content">
                        <div>辦公室電話：</div>
                        <div>{info.work_phone}</div>
                      </div>
                      <div className="content">
                        <div>傳呼機號碼：</div>
                        <div>{info.pager}</div>
                      </div>
                      <div className="content">
                        <div>電子郵件：</div>
                        <div>{info.e_mail}</div>
                      </div>
                      <div className="content">
                        <div>傳真機號碼：</div>
                        <div>{info.fax_number}</div>
                      </div>
                      <div className="content">
                        <div>電話號碼 (其他) ：</div>
                        <div>{info.alternative_phone}</div>
                      </div>
                    </div>
                  </div>
                  <div className="footer">
                    <hr className="hr2" />
                    <div className="back-btn">
                      <Button
                        style={{
                          width: "50%",
                          height: 50,
                          backgroundColor: "#7D6746",
                          border: 0,
                          borderRadius: 8,
                        }}
                        onClick={() => {
                          routeNavigate();
                        }}
                      >
                        返回主頁
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
