import React, { useState, useRef, useEffect } from "react";
import "../styles/Uploadphoto.css";
import background from "../img/png/become_mem_background.png";
import { ReactComponent as UploadIcon } from "../img/svg/upload.svg";
import { ReactComponent as JPGPNGIcon } from "../img/svg/JPGPNG.svg";
import { ReactComponent as DeleteIcon } from "../img/svg/delete.svg";
import {
  Accordion,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
  FormCheck,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { Line } from "rc-progress";
import Resizer from "react-image-file-resizer";
import Fileprogressbar from "../components/Fileprogressbar";
import { render } from "@testing-library/react";
import { LUA } from "../config";
import { memberInfo, DCnumbering } from "../helper/memberInfo";
import axios from "../helper/axios";
import { Config } from "../envConfig";

const fileTypes = ["JPEG", "PNG", "JPG"];

let counter = 0;

export default function Uploadphoto() {
  const [file, setFile] = useState([]);
  const [filelength, setfilelength] = useState(0);

  const [fileBase64, setFileBase64] = useState([]);

  const [dis, setDis] = useState(false);

  // const selectAndDrop = (uploadFile) => {
  //   console.log("uploadFile", uploadFile);
  //   console.log("counter", counter);
  //   if (uploadFile.length + counter > 2) {
  //     alert("More than 2");
  //   } else {
  //     counter += uploadFile.length;
  //     console.log("counter if", counter);
  //     if (counter == 2) {
  //       setDis(true);
  //     }
  //   }
  // };

  const uploadfile = (uploadFile) => {
    // setFile(uploadFile);
    let temp = [];
    console.log("uploadFile: ", uploadFile);

    if (uploadFile.length == 2) {
      for (let i = 0; i < uploadFile.length; i++) {
        Resizer.imageFileResizer(
          uploadFile[i],
          725,
          425,
          "JPEG",
          100,
          0,
          (uri) => {
            // console.log("looping", i);
            console.log(uri);
            temp = [...temp, uri];
            console.log("temp", temp);
            setFileBase64(temp);
            // setFileBase64([...fileBase64, uri]);
            // console.log("test", fileBase64);
          },
          "base64"
        );
      }
    }
    if (uploadFile.length == 1) {
      Resizer.imageFileResizer(
        uploadFile[0],
        725,
        425,
        "JPEG",
        100,
        0,
        (uri) => {
          // console.log(uri);
          let oneTemp = uri;
          setFileBase64([...fileBase64, oneTemp]);
          console.log("test", fileBase64);
        },
        "base64"
      );
      // console.log(response);
    }
  };

  const uploadCardAPI = async () => {
    const mdsToken = LUA.mdsToken;
    let apiResponse;
    const upload = {
      token: mdsToken,
      member_num: sessionStorage.getItem("memberNum"),
      file_upload: fileBase64,
    };
    console.log("log", upload);
    axios.post("/upload_api.php", upload).then((response) => {
      apiResponse = response.data;
      console.log("axios", response);
      if (apiResponse.success) {
        console.log("goood");
        setFile([]);
        setFileBase64([]);
        DCnumbering.DCnumbering = apiResponse.data.applicationNum;
        sessionStorage.removeItem("memberNum");
        routeNavigate();
      } else {
        alert(apiResponse.message);
        navigate(Config.path + "/loginLUA");
        sessionStorage.removeItem("memberNum");
      }
    });
    return;
  };

  // function Fileprogressbar(props) {
  //   let num = props.num;
  //   return (
  //     <Container style={{ paddingTop: 26 }}>
  //       <Row>
  //         <Col
  //           className="d-flex justify-content-center align-items-center"
  //           sm="auto"
  //           xs="auto"
  //         >
  //           <JPGPNGIcon className="JPGPNGIcon" />
  //         </Col>

  //         <Col
  //           className="d-flex flex-column"
  //           style={{ width: 844, height: "auto" }}
  //         >
  //           <Row>
  //             <Col className="d-flex flex-row justify-content-between">
  //               <p className="Filename">{file[num].name}</p>
  //               <p className="progresstext">Completed</p>
  //             </Col>
  //           </Row>
  //           <Line percent="100" trailColor="#E0E0E0" strokeColor="#7D6746" />
  //         </Col>

  //         <Col
  //           className="d-flex justify-content-center align-items-center"
  //           sm="auto"
  //           xs="auto"
  //         >
  //           <DeleteIcon className="DeleteIcon" />
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  const [ticked, setticked] = useState(false);
  const Click = () => {
    setticked(!ticked);
  };

  let navigate = useNavigate();
  const routeNavigate = () => {
    let path = Config.path + "/Verification";
    navigate(path);
  };

  const deleteFile = (fileName) => {
    console.log(fileName);
    counter--;
    setfilelength(counter);
    if (fileName == 0) {
      file.shift();
      fileBase64.shift();
      console.log(file);
      console.log(fileBase64);
    } else {
      file.pop();
      fileBase64.pop();
      console.log(file);
      console.log(fileBase64);
    }

    setDis(false);
  };

  return (
    <div className="Uploadphoto">
      <div
        className="bg"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Container>
          <Row>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S1white.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S2brown.png")} width={"100%"} />
            </Col>
            <Col
              md={"4"}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Image src={require("../img/png/S3white.png")} width={"100%"} />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ height: 124 }}
          >
            <p className="uploadtext">上載個人名片</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col
            className="d-flex justify-content-center align-items-center"
            md="auto"
          >
            <FileUploader
              classes={"uploadarea"}
              multiple={true}
              onTypeError={(err) => alert("We only accept JPEG, PNG or JPG")}
              //onSelect={selectAndDrop}
              //onDrop={selectAndDrop}
              handleChange={(e) => {
                if (fileBase64.length + e.length > 2) {
                  alert("More than 2");
                } else {
                  uploadfile(e);
                  console.log(fileBase64);
                  setFile(file.concat([...e]));
                  console.log(file);
                  counter += e.length;
                  setfilelength(counter);
                  if (counter == 2) {
                    setDis(true);
                  }
                }
                // uploadfile(e);
                // if (e.length == 1) {
                //   setFile([...file, e[0]]);
                // }
                // if (e.length == 2) {
                //   setFile([e[0], e[1]]);
                // }
                // console.log("file", file.length);
                // console.log("e length", e.length);
              }}
              disabled={dis}
              name="file"
              types={fileTypes}
              hoverTitle={" "}
              children={
                <div className="children">
                  <UploadIcon />
                  <p className="uploadtext">拖動個人名片以上傳</p>

                  <div className="uploadbtn" style={{ width: "auto" }}>
                    <Button
                      style={{
                        width: 135,
                        height: 48,
                        backgroundColor: "#C49837",
                        border: 0,
                        borderRadius: 8,
                      }}
                      className="btn"
                    >
                      <div>瀏覽文件</div>
                    </Button>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>

        <Row className="justify-content-md-center" style={{ paddingTop: 26 }}>
          <Col
            className="d-flex flex-column justify-content-center align-items-center"
            xs="auto"
            sm="auto"
          >
            {filelength == 2 ? (
              <>
                <Fileprogressbar
                  fileName={file[0].name}
                  delect={() => {
                    deleteFile(0);
                  }}
                />
                <Fileprogressbar
                  fileName={file[1].name}
                  delect={() => {
                    deleteFile(1);
                  }}
                />
              </>
            ) : filelength == 1 ? (
              <Fileprogressbar
                fileName={file[0].name}
                delect={() => {
                  deleteFile(0);
                }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Row>
          <Col
            className="d-flex align-items-end"
            style={{ height: 87 }}
            xl={{ offset: 1 }}
            xxl={{ offset: 2 }}
            xs="auto"
            sm="auto"
          >
            <FormCheck
              label={`本人同意及明白申請加入總監會需經過總監會籌委會審核`}
              className="tickbox"
              checked={ticked}
              onClick={Click}
            />
          </Col>
        </Row>
      </Container>

      <div className="div-btn">
        <Button
          style={{
            width: 135,
            height: 48,
            backgroundColor: "#7D6746",
            border: 0,
            borderRadius: 8,
          }}
          className="btn"
          onClick={() => {
            // routeNavigate();
            if (fileBase64.length == 0) {
              alert("請上傳卡片。" + "Please upload your card.");
            } else {
              uploadCardAPI();
              console.log(fileBase64);
              console.log("length: ", fileBase64.length);
              console.log("file", file);
            }
          }}
          disabled={ticked ? false : true}
        >
          <div>提交</div>
        </Button>
      </div>
    </div>
  );
}
